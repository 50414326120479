@import '@sass-mixins';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 120px;
  overflow: hidden;
  margin-bottom: -83px;
  @include breakpoint('sm-max') {
    padding-top: 45px;
    margin-bottom: -20px;
  }
}

.title {
  font-family: var(--font-header-primary);
  font-weight: 700;
  @include baseTitle(50px, 64px);

  letter-spacing: 0.58em;
  margin-bottom: 25px;
  text-align: center;
  color: #141720;

  @include breakpoint('sm-max') {
    @include baseTitle(30px, 64px);
    letter-spacing: 0.28em;
    margin-bottom: 13px;
  }
}

.titleCode {
  font-family: var(--font-header-primary);
  font-weight: 700;
  @include baseTitle(400px, 510px);

  text-align: center;
  color: #ffffff;
  z-index: 1;
  position: absolute;

  @include breakpoint-max-width(767px) {
    margin-bottom: 26px;
    font-size: 16px;
    line-height: 19px;
  }
}

.picture {
  width: 888px;
  height: 456px;
  position: relative;

  @include breakpoint-max-width(767px) {
    width: 350px;
    height: 247px;
  }
}

:global(.dark) {
  .title {
    color: #d7def9;
  }
}
